<template>
  <div class="page">
    <Breadcrumbs/>
    <div class="page-container block-container">
      <div class="column left-block large-block">
        <h1>Klantenportaal</h1>
        <p><a href="https://cp.hostingondemand.nl/">Klik hier om het klantenportaal te openen</a></p>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs.vue';

export default {
  name: 'CustomerPortal',
  components: {
    Breadcrumbs,
  },
}
</script>